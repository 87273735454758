export const kycStatusTypes = {
  new: ['new'],
  'in progress': ['in_progress', 'pending', 'retry', 'unverified'],
  verified: ['verified'],
  reject: ['reject'],
}
export const kycLevelTypes = {
  ADVANCED: 'advanced',
  UNLIMITED: 'unlimited',
}
export const kycExecutorTypes = {
  System: 'system',
  Admin: 'admin',
  User: 'user',
}
export const kycStatusColors = {
  'Not found': 'secondary',
  new: 'secondary',
  in_progress: 'warning',
  pending: 'warning',
  retry: 'warning',
  unverified: 'warning',
  verified: 'success',
  reject: 'danger',
}
export const kycLevelColors = {
  unlimited: 'primary',
  advanced: 'primary',
}
export const kucSections = [
  {
    short: 'kyc.sections.proofOfAddress.short',
    full: 'kyc.sections.proofOfAddress.full',
    api: 'poa',
    description: 'kyc.sections.sourceOfFund.description',
    key: 'proofOfAddress',
    note: 'kyc.sections.proofOfAddress.note',
  },
  {
    short: 'kyc.sections.sourceOfFund.short',
    full: 'kyc.sections.sourceOfFund.full',
    api: 'sof',
    description: 'kyc.sections.sourceOfFund.description',
    key: 'sourceOfFund',
    note: 'kyc.sections.sourceOfFund.note',
  },
  {
    short: 'kyc.sections.id.short',
    full: 'kyc.sections.id.full',
    description: 'kyc.sections.id.description',
    key: 'id',
    api: 'id',
    note: 'kyc.sections.id.note',
  },
  {
    short: 'kyc.sections.liveness.short',
    full: 'kyc.sections.liveness.full',
    description: 'kyc.sections.liveness.description',
    key: 'liveness',
    api: 'liveness',
    note: 'kyc.sections.liveness.note',
  },
  {
    short: 'kyc.sections.occupation.short',
    full: 'kyc.sections.occupation.full',
    description: 'kyc.sections.occupation.description',
    key: 'occupation',
    note: 'kyc.sections.occupation.note',
  },
  {
    short: 'kyc.sections.cdd.short',
    full: 'kyc.sections.cdd.full',
    api: 'cdd',
    description: 'kyc.sections.cdd.description',
    key: 'cdd',
    note: 'kyc.sections.cdd.note',
  },
  {
    short: 'kyc.sections.geo.short',
    full: 'kyc.sections.geo.full',
    api: 'geo',
    // description: 'kyc.sections.geo.description',
    key: 'geo',
    note: 'kyc.sections.geo.note',
  },
]

export const kycData = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}

export const KycRejectReasons = {
  id: [
    'faceMismatch',
    'frontSideNotFound',
    'frontSideNotFront',
    'backSideNotBack',
    'cddAlert',
    'documentExpired',
    'ageUnder18',
    'highRiskCountry',
  ],
  liveness: ['maskAttack', 'lowPrediction', 'faceMismatch'],
  sourceOfFund: [
    'blurredDocument',
    'missingInformation',
    'tamperedDocument',
    'invalidDocumentType',
    'documentExpired',
    'nameMismatch',
    'nonEnglishWithoutTranslation',
    'incompleteDeclaration',
    'unsignedOrIncompleteDocument',
    'missingIncomeDetails',
  ],
  proofOfAddress: [
    'blurredDocument',
    'missingInformation',
    'documentNotInApplicantsName',
    'documentExpired',
    'tamperedDocument',
    'invalidDocumentType',
    'nameOrAddressMismatch',
    'nonEnglishWithoutTranslation',
    'utilityBillNotInApplicantsName',
    'outdatedBill',
    'unsignedAgreement',
    'noRentalDates',
  ],
  cdd: ['cddAlert'],
  geo: ['vpnUser', 'countryMismatch'],
  custom: 'custom',
}

// 'kyc-page' - просмотр таблиц
// 'kyc-dashboard' - просмотр дашборда
// 'kyc-application-view' - просмотр заявки
// 'kyc-profile-view' - просмотр профиля
// 'kyc-review' - апрув реджект чексов и всей заявки
// 'kyc-download-pdf' - загрузка пдф
// 'kyc-set-responsible' - назначить кого-то
// 'kyc-change-status' - изменить апрув/реджект чекса и всей заявки
// 'kyc-view-photo' - просмотр фото документов
